$secondaryColor: #e7a45c;
$secondaryColorLight: lighten($secondaryColor, 10);
$secondaryColorUltraLight: lighten($secondaryColor, 30);
$secondaryColorDark: darken($secondaryColor, 10);

$primaryColor: #007975;
$primaryColorLight: lighten($primaryColor, 5);
$primaryColorUltraLight: lighten($primaryColor, 10);
$primaryColorLight70: lighten($primaryColor, 72);
$primaryColorDark: darken($primaryColor, 10);
$primaryColorUltraDark: darken($primaryColor, 20);

$backgroundColor: #1c1e24;
$backgroundColorLight5: lighten($backgroundColor, 5);
$backgroundColorLight10: lighten($backgroundColor, 10);
$backgroundColorLight15: lighten($backgroundColor, 15);
$backgroundColorLight20: lighten($backgroundColor, 20);
$backgroundColorLight25: lighten($backgroundColor, 25);
$backgroundColorLight45: lighten($backgroundColor, 45);
$backgroundColorLight50: lighten($backgroundColor, 50);
$backgroundColorLight60: lighten($backgroundColor, 60);
$backgroundColorLight70: lighten($backgroundColor, 70);
$backgroundColorDark5: darken($backgroundColor, 5);
$backgroundColorDark10: darken($backgroundColor, 10);
$backgroundColorContent: #fcfcfc;

$successColor: $primaryColor;
$successColorLight: lighten($primaryColor, 10);
$successColorUltraLight: lighten($primaryColor, 30);
$successDark: darken($primaryColor, 10);

$warningColor: $secondaryColor;
$warningColorLight: lighten($secondaryColor, 10);
$warningColorUltraLight: lighten($secondaryColor, 30);
$warningDark: darken($secondaryColor, 10);

$errorColor: #a41c2e;
$errorColorLight: lighten($errorColor, 10);
$errorColorUltraLight: lighten($errorColor, 30);
$errorDark: darken($errorColor, 10);

$infoColor: $primaryColor;
$infoColorLight: lighten($infoColor, 10);
$infoColorUltraLight: lighten($infoColor, 30);
$infoDark: darken($infoColor, 10);

$textColorPrimary: $backgroundColor;
$textColorSecondary: $backgroundColorLight20;
$textColorDisabled: $backgroundColorLight70;
$textColorLight: #fcfcfc;


:export {
    secondaryColor: $secondaryColor;
    secondaryColorLight: $secondaryColorLight;
    secondaryColorUltraLight: $secondaryColorUltraLight;
    secondaryColorDark: $secondaryColorDark;

    primaryColor: $primaryColor;
    primaryColorLight: $primaryColorLight;
    primaryColorLight70: $primaryColorLight70;
    primaryColorUltraLight: $primaryColorUltraLight;
    primaryColorDark: $primaryColorDark;
    primaryColorUltraDark: $primaryColorUltraDark;

    backgroundColor: $backgroundColor;
    backgroundColorLight5: $backgroundColorLight5;
    backgroundColorLight10: $backgroundColorLight10;
    backgroundColorLight15: $backgroundColorLight15;
    backgroundColorLight20: $backgroundColorLight20;
    backgroundColorLight25: $backgroundColorLight25;
    backgroundColorLight45: $backgroundColorLight45;
    backgroundColorLight50: $backgroundColorLight50;
    backgroundColorLight60: $backgroundColorLight60;
    backgroundColorLight70: $backgroundColorLight70;
    backgroundColorDark5: $backgroundColorDark5;
    backgroundColorDark10: $backgroundColorDark10;
    backgroundColorContent: $backgroundColorContent;

    successColor: $primaryColor;
    successColorLight: $primaryColorLight;
    successColorUltraLight: $primaryColorUltraLight;
    successDark: $primaryColorDark;

    warningColor: $secondaryColor;
    warningColorLight: $secondaryColorLight;
    warningColorUltraLight: $secondaryColorUltraLight;
    warningDark: $secondaryColorDark;

    errorColor: $errorColor;
    errorColorLight: $errorColorLight;
    errorColorUltraLight: $errorColorUltraLight;
    errorDark: $errorDark;

    infoColor: $infoColor;
    infoColorLight: $infoColorLight;
    infoColorUltraLight: $infoColorUltraLight;
    infoDark: $infoDark;

    textColorPrimary: $textColorPrimary;
    textColorSecondary: $textColorSecondary;
    textColorDisabled: $textColorDisabled;
    textColorLight: $textColorLight;
}


.Chat .messages {
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    /*justify-content: flex-end;*/
    gap: 10px;
    padding: 10px;
}
/*workaround because justify-content: flex-end; breaks scroll*/
.Chat:not(.project-blackboard) .messages > :first-child {
   margin-bottom: auto;
}
.Chat.project-blackboard .messages {
    padding: 0;
    flex-direction: column;
}

.Chat * {
    z-index: 1;
}
.Chat .background-container {
    position: relative;
}
.Chat .background-container::after {
    background: url('../../../public/chat_background.jpg');
    background-repeat: repeat;
    background-size: 300px;
    background-position: center;
    opacity: 0.3;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    z-index: 0;
}
.Chat.project-blackboard .background-container::after {
    content: none;
}

.Chat .emoji-mart {
    position: absolute;
    bottom: 65px;
    right: 13px;
    width: 50%;
}

.Chat .file-upload {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.Chat .file-upload * {
    margin: 0;
}

.Chat .reply-to {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.Chat.project-blackboard .reply-to{
    justify-content: flex-start;
}

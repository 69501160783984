.sector{
    animation: fadeIn 250ms ease-in-out ;
}

.customIcon{
    animation: fadeIn 1000ms ease-in-out ;
}

@keyframes fadeIn {
    0% {
        opacity:0;
    }
    50% {
        opacity:0.75;
    }
    100% {
        opacity:1;
    }
}
.blackboard-container {
    flex: 0 0 400px;
}
.react-swipeable-view-container {
    height: 100%
}
@media screen and (max-width: 1680px) {
    .blackboard-container {
        display: none !important;
    }
}
@import '../theme/variables.scss';

.Message {
    display: flex;
    align-items: center;
    gap: 5px;
}
.Message.left {
    align-self: flex-start;
    flex-direction: row;
}
.Message.right {
    align-self: flex-end;
    flex-direction: row-reverse;
}
/*
------------------------------------------------------------
Bubble
------------------------------------------------------------
*/
.Message .bubble {
    padding: 10px;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.Message.left .bubble {
    background-color: $backgroundColorLight10;
}
.Message.right .bubble {
    background-color: $primaryColor;
}
/* Embedded */
.Message.embedded-reply {
    align-self: stretch;
}

.Message.embedded-reply .bubble {
    flex: 1;
    flex-direction: row;
    align-items: center;
}
.Message.left.embedded-reply .bubble{
    background-color: $backgroundColor;
}
.Message.right.embedded-reply .bubble{
    background-color: $primaryColorDark;
}
/*
------------------------------------------------------------
Content
------------------------------------------------------------
*/
.Message p,
.ProjectBlackboardMessage p {
    white-space: pre-wrap;
}
.Message .ImagePopup,
.Message .VideoPopup {
    width: 250px;
    border-radius: 8px;
    overflow: hidden;
}
/* Download button */
.type3 a {
    border-radius: 8px;
    padding: 10px;
    color: white;
    text-decoration: none;
    display: flex;
    align-content: center;
    gap: 5px;
}
.Message.type3.left a {
    background-color: $backgroundColorLight15
}
.Message.type3.left a:hover {
    background-color: $backgroundColorLight20
}
.Message.type3.right a {
    background-color: $primaryColorLight;
}
.Message.type3.right a:hover {
    background-color: $primaryColorUltraLight;
}
/* Embedded */
.Message.embedded-reply .ImagePopup,
.Message.embedded-reply .VideoPopup {
    width: 50px;
}
/* Download button */
.Message.type3.embedded-reply span {
    display: none;
}
.Message .timestamp {
    font-size: 0.7rem;
    align-self: flex-end;
    opacity: 0.8;
}
.Message.embedded-reply .timestamp {
    display: none;
}
/*
------------------------------------------------------------
Reply button
------------------------------------------------------------
*/
.Message .reply-button {
    opacity: 0;
    transition: opacity 0.05s ease-in-out;
}
.Message:hover .reply-button {
    opacity: 1;
}
/* Reply to */
.reply-to .buttons,
.reply-to .replies {
    display: none !important;
}




/*
------------------------------------------------------------
Blackboard
------------------------------------------------------------
*/
.ProjectBlackboardMessage,
.ProjectBlackboardMessage .bubble {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
}
.ProjectBlackboardMessage .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.ProjectBlackboardMessage .replies {
    /* padding-left: 40px; */
    border-left: 3px solid $primaryColor;
}
.ProjectBlackboardMessage .buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.ProjectBlackboardMessage .replies .buttons {
    display: none;
}
.ProjectBlackboardMessage .bubble .ImagePopup,
.ProjectBlackboardMessage .bubble .VideoPopup {
    overflow: hidden;
    border-radius: 8px;
}
.ProjectBlackboardMessage.type3 a {
    background-color: $primaryColorLight;
    align-self: flex-start;
}
.ProjectBlackboardMessage.type3 a:hover {
    background-color: $primaryColorUltraLight;
}

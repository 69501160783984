.VideoPopup .preview {
    position: relative;
    cursor: pointer;
    display: flex;
}
.VideoPopup .button {
    position: absolute;
    top: 50%;
    left: 50%;
    color: rgb(196, 196, 196);
    transform: translateX(-50%) translateY(-50%);
}
.VideoPopup .preview:hover .button {
    color: white;
}
.loading-screen{
  position: fixed;
  display: flex;
  flex-direction:  column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  animation: fadein 0.5s ease-out normal backwards;
}

.overlay-background{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  /* background-color: rgba(0, 0, 0, 0.116); */
}


/*
----------------------------------------------------------------------
Animation
----------------------------------------------------------------------
*/

#svg{
    padding: 10px;
}

#bubble_top{
    fill: var(--secondarycolor);
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) infinite;
    animation-delay: 1s;
}

#bubble_right{
    fill: var(--primarycolor);
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) infinite;
    animation-delay: 6s;
}

#bubble_left{
    fill: var(--backgroundcolorLight);
    animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) infinite;
    animation-delay: 4s;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 1px, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 1px, 5px);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }




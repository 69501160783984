.project-card{
    z-index: 2;
    display: flex;
    flex-direction: row;
    transition: all 250ms;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.12),  0 1px 2px rgba(0, 0, 0, 0.24);
}

.project-card:hover{
    position: relative;
    box-shadow: 0 1.5rem 2.8rem rgba(0, 0, 0, 0.15), 0 1rem 1rem rgba(0, 0, 0, 0.15);
    transform: translate(0, -0.0.5rem) scale(1.01);
}

.project-card-limited{
    z-index: 2;
    padding: 1rem;
    transition: 250ms;
    border-radius: 0.8rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.12),  0 1px 2px rgba(0, 0, 0, 0.24);
    color: var(--backgroundcolorLight),
}

.project-card-limited:hover{
    position: relative;
    box-shadow: 0 1.5rem 2.8rem rgba(0, 0, 0, 0.15), 0 1rem 1rem rgba(0, 0, 0, 0.15);
    transform: translate(0, -0.0.5rem) scale(1.01);
}

.project-search-request-card{
    padding: 1rem;
    transition: 250ms;
    border-radius: 0.8rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.12),  0 1px 2px rgba(0, 0, 0, 0.24);
    color: var(--backgroundcolorLight),
}

.project-search-request-card:hover{
    z-index: 2;
    position: relative;
    box-shadow: 0 1.5rem 2.8rem rgba(0, 0, 0, 0.15), 0 1rem 1rem rgba(0, 0, 0, 0.15);
    transform: translate(0, -0.0.5rem) scale(1.01);
}

.margin-box{
    padding: 2px 10px 2px 10px;
    border-radius: 0.3rem;
    border: 1.5px #dadada solid;
}

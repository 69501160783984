@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import './components/theme/variables.scss';

:root {
    --secondarycolorDark: #a37448;
    --secondarycolor: #e9a667;
    --secondarycolorLight: #edb785;

    --primarycolorDark: #005959;
    --primarycolor: #008080;
    --primarycolorLight: #339999;

    --warningcolorDark: #721320;
    --warningcolor: #a41c2e;
    --warningcolorLight: #b64957;

    --backgroundcolorDark: #1c1d20;
    --backgroundcolor: #282a2e;
    --backgroundcolorLight: #535457;
    --backgroundcolorSuperLight: #c4c4c4;
    --backgroundcolorContent: #fcfcfc;

    --textcolor:rgb(123, 126, 138);
    --fontcolor: #d8d8d8;

    --gradient: linear-gradient(to left, rgb(41, 42, 46) 0%, rgb(43, 44, 49) 50%, rgb(49, 51, 56) 100%);
    --shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.12),  0 1px 2px rgba(0, 0, 0, 0.24);
}

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    width: 100vw;
    background-color: $backgroundColorContent;
    overflow-x: hidden;
}

code {
    font-family: 'Poppins', sans-serif;
}

.wrapper{
    display:flex;
    flex-direction: row;
    min-height: 100vh;
}

.content{
    min-height: 100vh;
    background-color: $backgroundColorContent;
    display: flex;
    flex-direction: column;
}

/*
----------------------------------------------------------------------
Standards
----------------------------------------------------------------------
*/

a {
    text-decoration: none;
    color: inherit;
    margin: 0;
}

/*
----------------------------------------------------------------------
wrapper / container
----------------------------------------------------------------------
*/


// .row{
//     display: flex;
//     flex-direction: row;
// }

// .column{
//     display: flex;
//     flex-direction: column;
// }

// .card{
//     width: 100%;
//     box-shadow: var(--shadow);
//     border-radius: 0.2rem;
//     transition: all 250ms;
// }

// .card:hover{
//     box-shadow: 0 1.5rem 2.8rem rgba(0, 0, 0, 0.15), 0 1rem 1rem rgba(0, 0, 0, 0.15);
//     transform: translate(0, -0.0.5rem) scale(1.01);
// }

// .card-header{
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     margin-bottom: 5px;
//     padding: 25px;
//     border-top-left-radius: 0.2rem;
//     border-top-right-radius: 0.2rem;
//     background-color: var(--backgroundcolor);
// }

// .card-header.expand{
//     height: 120px;
// }

// .card-title{
//     font-size: 30px;
//     font-weight: bold;
//     letter-spacing: 0.2rem;
//     color: var(--primarycolor);
// }

// .card-icon{
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     color: var(--secondarycolor);
//     font-size: 20px;
//     font-weight: bold;
//     width: 65px;
// }

// .card-content{
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     width: 100%;
//     padding: 10px 25px;
//     font-size: 18px;
//     font-weight: bold;
//     color: var(--textcolor);
//     letter-spacing: 0.2rem;
// }

// .card-content-left{
//     width: 100%;
// }
// .card-content-right{
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
// }

// .card-content-input-group{
//     display: flex;
//     flex-direction: row;
// }

// .card-content-calendar{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 300px;
//     margin-bottom: 20px;
// }

// .card-content-btn-container{
//     width: 100%;
// }
// .card p{
//     font-size: 15px;
//     color: var(--backgroundcolor);
//     letter-spacing: 0.1rem;
// }


/*
----------------------------------------------------------------------
Grid
----------------------------------------------------------------------
*/


// .grid-container{
// 	display: grid;
// 	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
// 	grid-gap: 10px;
// 	max-width: 1500px;
// 	margin: 0 auto;
// }


/*
----------------------------------------------------------------------
Animations
----------------------------------------------------------------------
*/

@keyframes fadein{
    0%{transform: scale(0);}
    80%{transform: scale(1.05);}
    100% {transform: scale(1);}
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}

/*
----------------------------------------------------------------------
Browser scrollbar
----------------------------------------------------------------------
*/

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: none;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #282a2e3b;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #282a2e77;
}

